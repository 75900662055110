.element-tooltip {
    background-color: #000000;
    color: #ffffff;
    /*font-family: 'Montserrat';*/
    font-style: normal;
    font-size: 16px;
    line-height: 20px;
    border-radius: 20px;
    padding: 2px 10px 15px 10px;
    width: 270px;
    text-align: left;
    position: fixed;
    z-index: 1;
    left: 34%;
    margin-left: -60px;
    min-height: 60px;
    /* height: 160px; */
}

.element-tooltip ::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 70%;
    margin-left: -105px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
}

.visible {
    visibility: visible;
}

.hidden {
    visibility: hidden;
}

.tooltip-body {
    /*font-family: 'Montserrat';*/
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    max-height: 120px;
    overflow-y: auto;
}
.feedback{
 display: flex;   
 margin-left: 30px;
}

.feedback:hover{
    cursor: pointer;
}

.feedback-tooltip-header{
font-weight: 600;
background-color: #000000;
color: #ffffff;
/*font-family: 'Montserrat';*/
font-style: normal;
font-size: 16px;
line-height: 20px;

}
.feedback-tooltip-body{
    font-weight: 400;
    background-color: #000000;
    color: #ffffff;
    /*font-family: 'Montserrat';*/
font-style: normal;
font-size: 16px;
line-height: 20px;
    text-align: left;
    }
.feedback-tooltip {
    background-color: #000000;
    color: #ffffff;
    /*font-family: 'Montserrat';*/
font-style: normal;
font-size: 16px;
line-height: 20px;
border-radius: 20px;
padding: 15px;
width: 282px;
text-align: center;
position: absolute;
z-index: 1;
bottom: 105%;
left: 34%;
margin-left: -60px;
}


.submit-feedback{
    /*font-family: 'Montserrat';*/
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    text-decoration-line:underline;
    color: #ECAC37;
    margin-bottom: 10px;
}


.feedback-tooltip ::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -105px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
  }

  .visible{
    visibility: visible;
  }

  .hidden{
    visibility: hidden;
  }



  .feedback-cont{
    position: relative;
  }
.resource-list ul li {
  /*font-family: "Montserrat";*/
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  padding: 0px;
  color: #367fb6;
  margin-top: 5px;
}
.resource-list ul {
  padding-left: 34px;
}
.resource-list ul li a {
  position: relative;
}
.upgrade-link {
  display: flex;
  justify-content: center;
}
.upgrade-button {
  margin: 0;
  border: none;
  font-size: 10px;
  border-radius: 3px;
  color: #333333;
  background-color: #ffba01;
  border: 1px solid #ffba01;
  height: 30px;
  padding: 0px 5px;
}

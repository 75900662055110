.cal-container {
    margin-top: 36px !important;
}

.cal-container .p-datepicker {
    background: rgba(250, 234, 205, 0.68);
    border: 0.362711px solid #EBEBEB;
    box-shadow: 0px 1.45084px 11.6067px rgba(170, 170, 170, 0.03);
    border-radius: 16px;
}

.cal-container .p-datepicker-today span {
    background: transparent;
    border-radius: 50%;
    /*font-family: 'Montserrat';*/
    font-style: normal;

}

.cal-container .p-highlight {
    background: #197AB5 !important;
    border-radius: 50%;
    /*font-family: 'Montserrat';*/
    font-style: normal;

    color: #FFFFFF !important;
}

.cal-container .p-datepicker-calendar tbody tr td span:hover {
    background: #197AB5 !important;
    border-radius: 50%;
    /*font-family: 'Montserrat';*/
    font-style: normal;

    /* Light / Selected */

    color: #FFFFFF !important;
}

body .p-datepicker table td>span {
    /*font-family: 'Montserrat';*/
    font-style: normal;
    font-weight: 600;
    font-size: 9px;
    line-height: 10px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #666666 !important;

}

.p-datepicker table thead tr th span {
    /*font-family: 'Montserrat';*/
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 10px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #000000;
}



/* Light / Primary */

.cal-container .p-datepicker-header {
    background: rgba(250, 234, 205, 0.68) !important;
    box-shadow: 0px 1.45084px 11.6067px rgba(170, 170, 170, 0.03);
    padding-right: 0px !important;
    padding-left: 0px !important;
}

.cal-container .p-calendar {
    background: rgba(250, 234, 205, 0.68);
    box-shadow: 0px 1.45084px 11.6067px rgba(170, 170, 170, 0.03);
    border-radius: 16px;
}

.cal-container .p-datepicker-title {
    /*font-family: 'Montserrat';*/
    font-style: normal;
    font-weight: 700;

    color: #333333;
    width: 50%;
}

.cal-container .p-datepicker-month {
    margin-right: 5px;

}

.cal-container .p-datepicker-title {
    display: flex;
    order: 0;
}

.cal-container .p-datepicker-prev {
    display: flex;
    order: 1;
    right: -44px;
    color: #333333 !important;
}

.cal-container .p-datepicker-next {
    display: flex;
    order: 2;
    color: #333333 !important;
}

.month-title {
    color: #ECAC37;
}

.cal-button {
    float: right;
    margin-right: 15px;
    margin-bottom: 5px;
    align-items: center;
    background: #197AB5;
    border-radius: 20px !important;
    padding: 0px 18px !important;
    /*font-family: 'Montserrat';*/
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;

}

.event-title {
    /*font-family: 'Montserrat';*/
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 10px;
    color: #605F5F;
    display: flex;
}

.event-org {
    /*font-family: 'Montserrat';*/
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    vertical-align: center;
    /* identical to box height */


    color: #605F5F;
}

.env-con {
    text-align: left;
    overflow-y: scroll;
    height: 250px;
}

.event-box {
    border-bottom: 1px solid #D9D9D9;
    padding-bottom: 21px;
    margin-bottom: 13px;
}


.event-loc {
    margin-top: 4px;
    /*font-family: 'Montserrat';*/
    font-style: normal;
    vertical-align: center;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #605F5F;
    display: flex;
    width: 100%;
}

.cal-view-btn {
    border-radius: 20px 0px 0px 20px;
    background: #FAEACD;
    width: 51px;
    padding: 7px;
}

.list-view-btn {
    background: #FAEACD;
    border-radius: 0px 20px 20px 0px;
    width: 51px;
    padding: 7px;


}

.list-view-btn:hover,
.cal-view-btn:hover {
    cursor: pointer;
}

.view-selected {
    background: #ECAC37;
}

.view-btn-container {
    display: flex;
    width: 102px;
    height: 35px;
    margin-left: auto;
    margin-top: 7px;
}

.cal-header-container {
    display: flex;
}

.show-more-txt:hover {
    cursor: pointer;
    ;
}

.calendar-block {
    width: 38%;
}

.calendar-event-list {
    width: 61%;
    padding-left: 33px;
    padding-right: 5px;
}

.new-label {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px 10px;
    gap: 10px;
    width: 48px;
    height: 18px;
    background: #219653;
    border-radius: 4px;
    /*font-family: 'Montserrat';*/
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
}

.event-date-view {
    /*font-family: 'Montserrat';*/
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 17px;
    display: flex;
    align-items: center;
    color: #333333;
}

.event-location-view {
    /*font-family: 'Montserrat';*/
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 17px;
    display: flex;
    align-items: center;
}

.calendar {
    display: flex;
    margin-left: 30px;
}

.calendar:hover {
    cursor: pointer;
}

.calendar-tooltip-header {
    font-weight: 600;
    background-color: #000000;
    color: #ffffff;
    /*font-family: 'Montserrat';*/
    font-style: normal;
    font-size: 16px;
    line-height: 20px;

}

.calendar-tooltip-body {
    font-weight: 400;
    background-color: #000000;
    color: #ffffff;
    /*font-family: 'Montserrat';*/
    font-style: normal;
    font-size: 16px;
    line-height: 20px;
    text-align: left;
}

.calendar-tooltip {
    background-color: #000000;
    color: #ffffff;
    /*font-family: 'Montserrat';*/
    font-style: normal;
    font-size: 16px;
    line-height: 20px;
    border-radius: 20px;
    padding: 15px;
    width: 480px;
    text-align: left;
    position: fixed;
    z-index: 1;
    left: 34%;

    margin-left: -60px;
    height: 200px;

}




.calendar-tooltip ::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 25%;
    margin-left: -100px;
    border-width: 7px;
    border-style: solid;
    border-color: black transparent transparent transparent;
}

.visible {
    visibility: visible;
}

.hidden {
    visibility: hidden;
}

.calendar-cont {

    position: relative;
}

.calendar-event {
    /*font-family: 'Montserrat';*/
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    max-height: 60px;
    overflow-y: auto;
}

.event-location,
.event-organization {
    /*font-family: 'Montserrat';*/
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    display: flex;
    align-items: center;
}

.rsvp-link {
    /*font-family: 'Montserrat';*/
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    display: flex;
    align-items: center;

    color: #367FB6;
}
.header-subtitle {
  padding: 10px 35px 0 40px;
  font-size: 18px;
  font-weight: bold;
  /*font-family: "Montserrat", sans-serif;*/
  color: #000000;
}

.resources-cards-block {
  display: flex;
  max-width: 1300px;
  padding: 40px 35px 40px 40px;
}

.resources-screen.webinars .card-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 560px;
  width: 33%;
  margin-bottom: 50px;
  margin-right: 25px;
  margin-left: 25px;
}

.resources-screen.webinars .card-container .card-title-container {
  position: relative;
  width: 120%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.resources-screen.webinars .card-container .card-title-container div {
  margin-left: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.resources-screen.webinars .card-container .card-title-container div img {
  cursor: pointer;
}

.resources-screen.webinars .card-container .card-title-container div img:last-child {
  margin-left: 8px;
}

.card-title {
  font-size: 22px;
  font-weight: 700;
  /*font-family: "Montserrat", sans-serif;*/
  color: #367fb6;
  margin: 0;
  text-align: left;
  max-width: 450px;
}

.card-content {
  margin: 0;
  list-style: none;
  text-align: left;
}

.card-list-content {
  padding: 10px;
}

.card-content {
  padding: 10px 0 20px;
}

.card-content span {
  padding: 8px 0;
  font-size: 15px;
  font-weight: 400;
  /*font-family: "Open Sans", sans-serif;*/
  color: #000000;
}

.card-content textarea {
  width: 300px;
  min-height: 120px;
  resize: vertical;
  padding: 8px 4px;
  font-size: 15px;
  font-weight: 400;
  /*font-family: "Open Sans", sans-serif;*/
  color: #000000;
}

.card-video {
  border: 7px solid rgb(236, 172, 55);
  border-radius: 10px;
  margin-bottom: 10px;
}

.card-btn {
  background: rgb(50, 126, 184);
  border-radius: 50px;
  font-size: 16px;
  font-weight: 500;
  /*font-family: "Montserrat", sans-serif;*/
  color: #ffffff;
  border: none;
  outline: none;
  padding: 11px 10px;
  cursor: pointer;
}

.card-btn:hover {
  background: rgb(43, 105, 153) !important;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.4);
}

.resources-screen .add-new-post,
.resources-screen .add-btn-container {
  padding-left: 40px;
}

.resources-screen .new-post-header {
  width: 300px;
}

.resources-screen .new-post-date-container,
.resources-screen .new-post-link-container {
  padding-left: 100px;
}

.resources-screen .new-post-link-helper {
  left: 10px;
}

.resources-screen .new-post-date {
  width: 500px;
  height: 75px;
  font-size: 15px;
  font-weight: 400;
  /*font-family: "Open Sans", sans-serif;*/
  color: #000000;
  border: 1px solid #979797;
}

.resources-screen.webinars .resources-cards-block {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  flex-wrap: wrap;
  justify-content: center;
}


.resources-screen.webinars .edit-link-input {
  width: 300px;
  padding: 8px 4px;
  margin-bottom: 15px;
  font-size: 15px;
  font-weight: 400;
  /*font-family: "Open Sans", sans-serif;*/
  color: #000000;
}

@media (max-width: 900px) {
  .cards-column:last-child {
    margin-left: 0;
  }
}

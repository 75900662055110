.pipeline-title {
  /*!*font-family: "Montserrat";*!*/
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  margin-top: 5px;
  color: #000000;
}

.pipeline-date {
  /*!*font-family: "Montserrat";*!*/
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;

  color: #605f5f;
  margin-top: 5px;
}
.pipeline-change {
  /*font-family: "Montserrat";*/
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  margin-top: 5px;

  color: #ecac37;
}
.pipeline-card {
  border-bottom: 1px solid #d8d8d8;
  margin: 10px 0px;
  padding: 10px;
  text-align: left;
}

.pipeline-name {
  /*font-family: "Montserrat";*/
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  margin-top: 5px;

  color: #197ab5;
}

.search-box {
  display: inline-flex;
  justify-content: space-evenly;
  margin-bottom: 10px;
}
.serach-dropdown-list {
  background: #ffffff;
  border: 3px solid #d9d9d9 !important;
  border-radius: 39px !important;
  box-sizing: border-box;
  padding: 3px;
}

.type {
  margin-right: 3px;
  margin-left: -8px;
}

.serach-dropdown-list .p-dropdown-label {
  /*font-family: "Montserrat" !important;*/
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 20px !important;

  color: #000000 !important;
}

.search-box-sep {
  border-bottom: 4px solid #d9d9d9;
  margin: 0px -20px;
}

.bid-status {
  /* display: flex; */
  width: 33px;
  height: 35px;
  margin-left: auto;
  margin-top: 7px;
  position: relative;
}

.pipeline-updates-header {
  display: flex;
}

.bidstatus-tooltip {
  background-color: #000000;
  /*font-family: "Montserrat";*/
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-decoration-line: none !important;
  color: #ffffff;
  border-radius: 20px;
  padding: 7px;
  width: 282px;
  text-align: left;
  position: absolute;
  z-index: 1;
  bottom: 105%;
  right: -73%;
  margin-left: -60px;
}

.bidstatus-tooltip ::after {
  content: "";
  position: absolute;
  top: 100%;
  right: 12%;
  margin-left: -105px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.bid-status-icon {
  display: flex;
}

.bid-status-icon:hover {
  cursor: pointer;
}

.pipeline-link {
  /*font-family: "Montserrat";*/
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;

  color: #197ab5;
}

.pipeline-loader{
  margin-top: 0;
  top:30%;
}
.update-date {
  /*font-family: "Montserrat";*/
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  width: 70%;
	margin-bottom: 0;
}

.update-cont h1,
.update-cont h2,
.update-cont h3 {
  /*font-family: "Montserrat";*/
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;

  color: #000000;
}

.update-cont ul li,
.update-cont p,
.update-cont {
  /*font-family: "Montserrat";*/
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;

  color: rgba(96, 95, 95, 0.7);
}
.update-cont a {
  /*font-family: "Montserrat";*/
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;

  color: #197ab5;
}

.view-details {
  /*font-family: "Montserrat";*/
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  float: right;
  color: #197ab5;
}

.search-box-sep {
  border-bottom: 4px solid #d9d9d9;
  margin: 0px -20px;
}

.update-seperator {
  border-bottom: 1px solid #d8d8d8;
}

.update-back-hover:hover {
  background-color: rgb(250 234 205 / 60%);
}

/* .serach-dropdown-list {
    background: #ffffff;
    border: 3px solid #d9d9d9 !important;
    border-radius: 39px !important;
    box-sizing: border-box;
    padding: 3px;
  } */

.aid-search {
  background: #ffffff;
  border: 3px solid #d9d9d9 !important;
  border-radius: 39px !important;
  box-sizing: border-box;
  padding: 3px;

  /*font-family: "Montserrat" !important;*/
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 20px !important;

  color: #000000 !important;
}
.aid-search::placeholder {
  /*font-family: "Montserrat";*/
  font-style: italic;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */

  color: rgba(0, 0, 0, 0.44);
}

.update-source {
  background: #f46e6e;
  border-radius: 15px;
  /*font-family: "Montserrat";*/
  font-style: normal;
  font-weight: 400;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
  margin-left: 30px;
  font-size: 12px;
  padding: 2px 16px;
  line-height: 12px;
  height: 25px;
}
.calendar-input input {
  background: inherit !important;
}

.update > div > div { 
	/* display: flex; */
  width: 100%;
  justify-content: space-between;
	align-items: center;
}
.update > div > p{
	margin: 8px 0px;
}
.add-update-btn{
  display: flex;
  margin-left: auto;
}
.notesTextarea {
  width: 90%;
  height: 200px;
  resize: vertical;
  margin: 5px;
  color: rgb(51, 51, 51);
  background: rgb(255, 255, 255);
  padding: 0.429em;
  border: 1px solid rgb(166, 166, 166);
  border-radius: 10px;
}

.notesTextarea:focus {
  outline: 0 none;
  outline-offset: 0;
  border-color: rgba(24, 122, 181, 1);
}
.customColumn{
  border-radius: 10px;
  border: 1px solid rgb(166, 166, 166);
  height: 35px;
}
.customColumn:focus{
  outline: 0 none;
  outline-offset: 0;
  border-color: rgba(24, 122, 181, 1);
}
.file-pop-up {
  position: absolute;
  width: 180px;
  text-align: left;
  font-size: 16px;
  background: #363636;
  color: #fff;
  padding: 5px;
  top: -120px;
  left: -160px;
}

.extended-contact-table td {
  text-align: center;
}

.extended-contact-table .p-datatable-scrollable-body {
  overflow: hidden;
}

.extended-contact-table .p-datatable-scrollable-header-box {
  margin-right: 0 !important;
}

.contacts_description {
  cursor: pointer;
}

.no-attachments {
  margin-left: 10px;
  font-weight: normal;
}

#contract-input > input{
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 22rem;
}
.contract-panel{
  border-radius: 20px !important;
}
#contracts{
  top: 9% !important;
  left: 36%!important;
  right: none!important;
  position: fixed !important;
}

.status-dropdown {
  width: 100%;
  height: 38px;
  display: flex;
  align-items: center;
  background-color: hsl(0,0%,100%);
  border-color: hsl(0,0%,80%) !important;
  border-radius: 4px !important;
  border-style: solid !important;
  border-width: 1px !important;
  cursor: default;
}

.status-dropdown .p-placeholder {
  margin-left: 4px;
  color: black !important;
  /*font: 16px Montserrat !important;*/
}

.status-dropdown span.p-inputtext {
  margin-left: 4px;
  color: black !important;
  /*font: 16px Montserrat !important;*/
}

.status-dropdown .p-dropdown-trigger {
  width: 2em !important;
}

.status-dropdown .p-dropdown-trigger-icon {
  font-size: .8em !important;
  color: hsl(0,0%,80%) !important;
}

.files-download-links {
  color: #999999;
}

.contract-panel.share {
  max-height: 700px;
}

.share-overlay-height {
  max-height: 698px;
}

@media (max-height: 1000px) {
  .contract-panel.share {
    max-height: 80vh;
  }

  .share-overlay-height {
    max-height: 79vh;
  }
}

.header-subtitle {
  padding: 10px 35px 0 40px;
  font-size: 18px;
  font-weight: bold;
  /*font-family: "Montserrat", sans-serif;*/
  color: #000000;
}

.resources-screen.resources .resources-cards-block {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 1300px;
  padding: 40px 35px 40px 40px;
  justify-content: center;
}

.resources-screen.resources .card-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 560px;
  width: 50%;
  margin-bottom: 50px;
  margin-right: 25px;
  margin-left: 25px;
}

.card-title {
  font-size: 22px;
  font-weight: 700;
  /*font-family: "Montserrat", sans-serif;*/
  color: #367fb6;
  margin: 0;
  text-align: left;
  max-width: 450px;
}

.card-content {
  margin: 0;
  list-style: none;
  text-align: left;
}

.card-list-content {
  padding: 10px;
}

.card-content {
  padding: 10px 0 20px;
}

.card-content span {
  padding: 8px 0;
  font-size: 15px;
  font-weight: 400;
  /*font-family: "Open Sans", sans-serif;*/
  color: #000000;
}

.card-btn {
  background: rgb(50, 126, 184);
  border-radius: 28px;
  font-size: 16px;
  font-weight: 500;
  /*font-family: "Montserrat", sans-serif;*/
  color: #ffffff;
  border: none;
  outline: none;
  padding: 10px 35px;
}

.card-btn:hover {
  background: rgb(43, 105, 153) !important;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.4);
}

.new-post-date-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  width: 550;
  height: 800;
  font-size: 15px;
  font-weight: 400;
  /*font-family: "Open Sans", sans-serif;*/
  border: 3px solid rgb(50, 126, 184);
  border-radius: 20px;
  max-width: 600px;
}

.new-post-date-big {
  width: 100%;
  height: 60%;
  min-height: 200px;
  color: #000000;
  border: 1px solid #979797;
  margin-bottom: 5px;
  border-radius: 2px;
  -webkit-box-align: center;
  font-family: "Roboto" !important;
}
.new-post-date-small {
  width: 100%;
  height: 10%;
  min-height: 20px;
  color: #000000;
  border: 1px solid #979797;
  margin-bottom: 5px;
  border-radius: 2px;
  -webkit-box-align: center;
  font-family: "Roboto" !important;
}

.container-dropzone {
  width: 100%;
}

.dropzone {
  width: 100%;
  height: 20%;
  border: 1px solid black;
  cursor: pointer;

  flex: 1 1 0%;
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: rgb(238, 238, 238);
  border-style: dashed;
  background-color: rgb(250, 250, 250);
  color: rgb(189, 189, 189);
  outline: none;
  transition: border 0.24s ease-in-out 0s;
  margin-bottom: 5px;
}

.p-grid {
    margin-top: 0px !important;
  }
.actions-column {
    display: flex;
    align-items: center;
    justify-content: center;
}

.actions-column img:first-child {
    margin-right: 10px;
}

.name-with-view {
    width: 100%;
    margin-right: 10vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.name-with-view .view-switch-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.name-with-view .view-switch-container .view-switch-table {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 32px;
    border-radius: 20px 0 0 20px;
    cursor: pointer;
}

.name-with-view .view-switch-container .view-switch-card {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 32px;
    border-radius: 0 20px 20px 0;
    cursor: pointer;
}

.projects-card-view-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 30px;
    margin-right: 15px;
}

.projects-card-view-container .projects-card-view-block-container {
    width: 49%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    padding: 15px 25px;
    border: 1px solid #D8D8D8;
    border-radius: 16px;
    margin-bottom: 10px;
}

.projects-card-view-container .projects-card-view-block-container:nth-child(1n) {
    margin-right: 1%;
}

.projects-card-view-container .projects-card-view-block-container .project-number-container {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #605F5F;
}

.projects-card-view-container .projects-card-view-block-container .project-name {
    margin: 8px 0;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #367FB6;
    text-align: left;
}

.projects-card-view-container .projects-card-view-block-container .project-description {
    padding: 0;
    margin: 0;
    text-align: left;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #979797;
}

.projects-card-view-container .projects-card-view-block-container .project-info-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-top: 1px solid #D9D9D9;
    border-bottom: 1px solid #D9D9D9;
    margin-top: 8px;
    padding: 8px 0;
}

.projects-card-view-container .projects-card-view-block-container .project-info-container.no-top {
    border-top: unset;
    padding: 0;
}

.projects-card-view-container .projects-card-view-block-container .project-info-container span {
    margin-left: 3px;
    font-size: 16px;
    line-height: 20px;
    color: #605F5F;
    text-align: left;
    /*font-family: 'Montserrat', sans-serif !important;*/
}

.projects-card-view-container .projects-card-view-block-container .project-info-container b {
    font-weight: 700!important;
}

.projects-card-view-container .projects-card-view-block-container .project-info-container .project-info-block {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.projects-card-view-container .projects-card-view-block-container .project-info-container .project-info-block:first-child {
    margin-bottom: 8px;
}

.projects-card-view-container .projects-card-view-block-container .project-info-container .project-info-block div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.projects-card-view-container .projects-card-view-block-container .project-info-container .project-info-dates {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.projects-card-view-container .projects-card-view-block-container .project-info-container .project-progress-bar {
    position: relative;
    width: 100%;
    margin: 8px 0;
}

.projects-card-view-container .projects-card-view-block-container .project-info-container .project-progress-bar p {
    position: absolute;
    right: 10px;
    margin: 0;
    padding: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #605F5F;
    z-index: 10;
}

.projects-card-view-container .projects-card-view-block-container .project-info-container .p-progressbar {
    height: 16px;
    background: rgba(217, 217, 217, 0.5);
    border-radius: 16px;
}

.projects-card-view-container .projects-card-view-block-container .project-info-container .p-progressbar .p-progressbar-value {
    background: #6DC14D;
}

.projects-card-view-container .projects-card-view-block-container .project-info-container .project-progress-bar span {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #605F5F;
}

.projects-card-view-container .projects-card-view-block-container .project-buttons-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 16px;
}

.projects-card-view-container .projects-card-view-block-container .view-button {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF;
    padding: 7px 25px;
    background: #ECAC37;
    border: none;
    border-radius: 27px;
    cursor: pointer;
}

.projects-card-view-container .projects-card-view-block-container .notes-button {
    padding: 7px 25px;
    background: #367FB6;
    border: none;
    border-radius: 27px;
    cursor: pointer;
}

.projects-card-view-container .projects-card-view-block-container .notes-button div {
    display: flex;
    align-items: center;
}

.projects-card-view-container .projects-card-view-block-container .notes-button div span {
    margin-left: 3px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF;
}


.view-column-btn {
    width: 72px;
    height: 26px;
    background: #367FB6;
    border-radius: 16px;
    border-width: 0;
    cursor: pointer;
}

.view-column-btn span {
    margin-left: 5px;
    margin-bottom: 1.5px;
    /*font-family: 'Open Sans', sans-serif;*/
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    color: #FFFFFF;
}

.view-column-btn div {
    display: flex;
    align-items: center;
}

.card-view-with-export {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
}

.card-view-with-export .button-export {
    position: absolute;
    right: 30px;
    top: -17px;
}

.button-export button span {
    /*height: 16px;*/
    /*width: 16px;*/
    font-size: 16px !important;
}

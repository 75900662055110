.header-subtitle {
  padding: 10px 35px 0 40px;
  font-size: 18px;
  font-weight: bold;
  /*font-family: "Montserrat", sans-serif;*/
  color: #000000;
}

.cdcs-list-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 25px 40px;
}

.cdcs-search-block {
  display: flex;
  justify-content: flex-start;
  width: 100%;
}

.cdcs-search-block input {
  max-width: 300px;
  width: 100%;
  padding: 4px 7px;
  margin-right: 20px;
  border: 3px solid #999999;
  border-radius: 8px;
  outline: none;
  font-family: "Roboto" !important;
}

.cdcs-search-block input::placeholder {
  /*font-family: "Montserrat", sans-serif;*/
  color: #999999;
  font-size: 14px;
  font-weight: 400;
}

.card-btn {
  background: rgb(50, 126, 184);
  border-radius: 28px;
  font-size: 14px;
  font-weight: 500;
  /*font-family: "Montserrat", sans-serif;*/
  color: #ffffff;
  border: none;
  outline: none;
  padding: 10px 50px;
}

.card-btn:hover {
  background: rgb(43, 105, 153) !important;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.4);
}

.country-list {
  padding: 25px 0;
}

.list-title {
  font-size: 22px;
  font-weight: 700;
  /*font-family: "Montserrat", sans-serif;*/
  color: #367fb6;
  text-align: left;
  margin: 0 15px 0 0;
}

.country-list ul {
  padding: 0 15px;
  margin: 10px 0 15px;
}

.list-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  list-style: none;
  text-align: left;
  font-weight: bold;
  margin: 16px 0;
}

.list-item span {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 4px;
}

.list-item span:hover {
  color: rgb(214, 156, 32);
}

.list-item .editIcon {
  width: 25px !important;
  margin-left: 15px;
  cursor: pointer;
}

.list-item .removeeIcon {
  width: 25px !important;
  margin-left: 8px;
  cursor: pointer;
}

.list-item-with-children {
  display: flex;
  flex-direction: column;
  justify-content: center;
  list-style: none;
  text-align: left;
  font-weight: bold;
  margin: 20px 0;
}

.top-li {
  display: flex;
  align-items: center;
}

.edit-current-name {
  font-weight: bold;
  font-size: 16px;
}

.edit-current-link {
  margin-top: 5px;
  border-top: 0;
  border-right: 0;
  border-left: 0;
  font-size: 14px;
}

.input-error {
  border-color: red !important;
}

.new-post-link-container {
  position: relative;
}

.link-error-message {
  position: absolute;
  right: 56%;
  top: 26px;
  font-size: 12px;
  color: red;
  font-style: italic;
}

.bottom-li {
  position: relative;
}

.bottom-li-error-text {
  position: absolute;
  left: 30px;
  top: 27px;
  font-size: 11px;
  color: red;
  font-style: italic;
}

.resources-screen input:focus-visible {
  outline: unset;
}

.list-title-block {
  display: flex;
  align-items: center;
}

.edit-current-continent {
  font-size: 22px;
  font-weight: 700;
  /*font-family: "Montserrat", sans-serif;*/
  color: #367fb6;
  margin-right: 15px;
}

.list-country-child {
  margin: 8px 0 8px 16px;
  text-align: left;
}

.list-country-child span {
  cursor: pointer;
}

.list-country-child span:hover {
  color: rgb(214, 156, 32);
}

.list-country-child .editIcon {
  width: 25px !important;
  margin-left: 15px;
  cursor: pointer;
}

.list-country-child .removeeIcon {
  width: 25px !important;
  margin-left: 8px;
  cursor: pointer;
}

.p-datatable-tbody td {
    word-break: break-word;
}

.no-attachments {
    margin-left: 10px;
    font-weight: normal;
}

.grants-history-table td {
    text-align: center;
}

#long-text > input{
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 22rem;
    /* margin-left: 8px; */
}
#grants-overlay{
    top: 10% !important;
    left: 36%!important;
    right: none!important;
    position: fixed !important;
}

.grants .p-dialog-content em, .grants .p-dialog-content i ,.grants .p-dialog-content b,.grants .p-dialog-content strong, .grants .p-dialog-content mark,.grants .p-dialog-content small,.grants .p-dialog-content del,.grants .p-dialog-content ins,.grants .p-dialog-content sub,.grants .p-dialog-content sup{
    color: #ffff !important;
}

.share-modal-body .email-option {
    font-size: 14px;
    font-style: italic;
    margin-bottom: 8px;
}

.share-modal-body .external-users-input {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}
.share-modal-body .external-users-input input {
    width: 100%;
}

.share-modal-body .external-users-input button {
    margin-left: 10px;
}
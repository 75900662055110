body .p-multiselect .p-multiselect-trigger {
    background-color: transparent !important;
}

.dashboard-market-preferences .p-multiselect-header .p-checkbox.p-component {
    display: none;
}

.dashboard-market-preferences .p-multiselect-header {
    padding-right: 5px !important;
}

.dashboard-market-preferences .p-multiselect-header .p-multiselect-filter-container {
    margin-right: 10px;
}

.dashboard-market-preferences .p-multiselect .p-multiselect-panel {
    right: -60px;
    margin: 5px 51px;
}

.dashboard-market-preferences .p-multiselect-close-icon {
    font-size: 12px !important;
    width: 8px;
}

.announcement-inner {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    margin-bottom: 10px;
}

.announcement-inner p {
    text-align: left;
    margin-bottom: 5px;
}

.announcement-inner .link {
    cursor: pointer;
}

.announcement-inner .no-spaces {
    margin-top: 15px;
}

.announcement-inner .no-spaces p {
    margin: 0 0 4px;
}

.preference-ddl {
    margin: 10px 0;
    border-radius: 20px;
    height: 24px;
    width: 61px;
    padding: 2px;
    margin-right: 4px;
    background: #FFFFFF;
    border: 2px solid #D9D9D9;
    font-family: "Roboto" !important;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #000000;
}
.img-centered-clipped {
  object-fit: cover;
}

.input-textarea {
  font-family: "Roboto";
  border-radius: 20px;
}

.tag {
  font-size: 14px;
  padding: .3em .4em .4em;
  margin: 0 .1em;
  background: #367FB6;
  border-radius: 13px;
}

.image-upload-button {
  /* display: flex; */
}

.image-upload-button>button {
  background: #ECAC37;
  width: auto;
  border-radius: 14px;
  padding: 3px;
  border: none;
  cursor: pointer;
  margin-top: 10px;
  margin: auto;
}

.image-upload-icon {
  font-size: 2.5rem !important;
}

.profile-picture-title {
  text-align: center;
  padding-bottom: 7px;
}

.profile-image-container {
  border-radius: 50%;
  display: flex;
  height: 120px;
  width: 120px;
  justify-content: center;
  border: 1px solid var(--mist-gray);
  margin: auto;
  margin-bottom: 15px;
  align-items: center;
}

.side-panel {
  z-index: 1000;
  transform-origin: center bottom;
  top: 0 !important;
  right: 0 !important;
  max-width: 600px;
  left: 77% !important;
}

.side-panel-header {
  height: 80px;
  background: #FAEACD;
  box-shadow: -3px 0px 11px rgba(0, 0, 0, 0.14);
  padding: 27px;
  align-items: flex-start;
  margin: auto;
}

.p-overlaypanel .p-overlaypanel-content {
  padding: 0px !important;
  height: 100% !important;
}

.field-inputs {
  width: 100%;
  height: 32px;
  font-size: 13px;
  padding: 0px 15px;
  margin: 5px 0px;
  border-radius: 20px !important;
  font-family: "Roboto" !important;
}

.field-titles {}

.edit-user-invite {
  background: #FAEACD;
  border-radius: 26.5px;
  margin: 5px !important;
  height: 35px !important;
  padding: 6px !important;
  border: none;
  width: 120px !important;
  font-weight: 600;
  /*font-family: 'Montserrat';*/
  font-style: normal;
  font-size: 16px;
  line-height: 20px;


  color: #605F5F;
}

.edit-user-invite:hover {
  background: #ECAC37;
  cursor: pointer;

}


.edit-user-edit {
  background: #ECAC37;
  border-radius: 26.5px;
  margin: 5px !important;
  height: 35px !important;
  padding: 6px !important;
  border: none;
  width: 120px !important;
  /*font-family: 'Montserrat';*/
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;

  color: #605F5F;
}

.buttton-elements {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.field-titles {
  font-size: 12px;
  padding: 5px;
}

.admin-action-button {
  background: none !important;
  color: black !important;
  font-size: 14px !important;
  margin: 0px !important;
  height: 35px !important;
  padding: 3px !important;
  border: none;
}

.admin-action-button:hover {
  box-shadow: none !important;
}

.cell-data {
  text-align: left !important;
  padding-left: 4px !important;
  text-transform: capitalize;
}

.user-status-button-active {
  border-radius: 19px;
  background: #6DC14D;
  border: none;
  padding: 5px 9px;
  color: #FFFFFF;
}

.user-status-button-pending {
  border-radius: 19px;
  background: #ECAC37;
  border: none;
  padding: 5px 9px;
  color: #FFFFFF;
}

.user-status-button-active .user-status-button-pending :hover {
  background-color: inherit;
}

.subscription-container {
  border: 1px solid #D9D9D9;
  padding: 20px;
  background: #FFFFFF;
  box-sizing: border-box;
  border-radius: 35px;
  height: 400px;
}

.subscription-header-text {
  text-align: left;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #605F5F;
}

.plus-section {
  display: flex;
  border: 1px solid #ECAC37;
  box-sizing: border-box;
  border-radius: 8px;
}

.subscription-footer {
  display: flex;
  text-align: left;
  padding-top: 45px;
  font-size: 13px;
  line-height: 15.85px;
  font-style: normal;
  font-weight: 400;
  color: #605F5F;

}

.subscription-footer-text {
  /* text-align: left;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px; */
}

.sub-button {
  font-weight: bold;
  margin: auto !important;
  height: 40px;
  border-radius: 65px;
  font-size: 1em;
  background-color: rgb(238, 173, 36);
  width: 30rem;
  padding: 8px 24px;
  font-size: 13px;
}

.required {
  color: red;
}

.swal2-styled.swal2-confirm {
  border-radius: 20px !important;
}

.swal2-styled.swal2-cancel {
  border-radius: 20px !important;
}

.swal2-title {
  font-size: 22px !important;
  /*font-family: "Montserrat" !important;*/
}

.swal-username {
  text-transform: capitalize;
}

.email-inner {
  text-transform: capitalize;
  background-color: #e7e7e7;
  font-size: 16px;
  padding: 5px 15px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 22rem;
  height: 40px;
  line-height: 26px;
}

.toggle-mask-container {
  display: grid;
}

.toggle-mask {
  right: 0;
  cursor: pointer;
}

.user-label {
  /*font-family: 'Montserrat';*/
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;

  color: #605F5F;
}

.user-input {
  border: 2px solid #D9D9D9 !important;
  border-radius: 20px !important;
  /*font-family: 'Montserrat' !important;*/
  font-style: italic !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 20px !important;
  color: #000000 !important;
  width: 100% !important;
  height: 40px !important;
  padding: 0px 15px !important;
  background: #FFFFFF !important;
  /* box-shadow: -3px 0px 11px rgba(0, 0, 0, 0.14) !important; */
  margin-top: 8px !important;
}

.user-input::placeholder {
  /*font-family: 'Montserrat' !important;*/
  font-style: italic !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 20px !important;
  color: #A6A6A6 !important;

}

.user-textarea {
  border: 2px solid #D9D9D9 !important;
  border-radius: 20px !important;
  /*font-family: 'Montserrat' !important;*/
  font-style: italic !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 20px !important;
  color: #A6A6A6 !important;
  width: 100% !important;
  padding: 10px 10px !important;
  background: #FFFFFF !important;
  /* box-shadow: -3px 0px 11px rgba(0, 0, 0, 0.14) !important; */
  margin-top: 8px !important;
}

.user-textarea::placeholder {
  /*font-family: 'Montserrat' !important;*/
  font-style: italic !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 20px !important;
  color: #A6A6A6 !important;

}

.new-user-panel {
  width: 100%;
}
.header-subtitle {
  padding: 10px 35px 0 40px;
  font-size: 18px;
  font-weight: bold;
  /*font-family: "Montserrat", sans-serif;*/
  color: #000000;
}

.iee-list-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 25px 40px;
}

.iee-search-block {
  display: flex;
  justify-content: flex-start;
  width: 100%;
}

.iee-search-block input {
  max-width: 300px;
  width: 100%;
  padding: 4px 7px;
  margin-right: 20px;
  border: 3px solid #999999;
  border-radius: 8px;
  outline: none;
  font-family: "Roboto" !important;
}

.iee-search-block input::placeholder {
  /*font-family: "Montserrat", sans-serif;*/
  color: #999999;
  font-size: 14px;
  font-weight: 400;
}

.card-btn {
  background: rgb(50, 126, 184);
  border-radius: 28px;
  font-size: 14px;
  font-weight: 500;
  /*font-family: "Montserrat", sans-serif;*/
  color: #ffffff;
  border: none;
  outline: none;
  padding: 10px 50px;
}

.card-btn:hover {
  background: rgb(43, 105, 153) !important;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.4);
}

.country-list {
  padding: 25px 0;
}

.iee-list-section .list-title {
  font-size: 22px;
  font-weight: 700;
  /*font-family: "Montserrat", sans-serif;*/
  color: #367fb6;
  margin: 0;
  text-align: left;
}

.iee-list-section .country-list ul {
  display: flex;
  padding: 0 15px;
  margin: 10px 0 15px;
}

.iee-list-section .list-item {
  position: relative;
  list-style: none;
  text-align: left;
  font-weight: bold;
  margin: 20px 0;
  cursor: pointer;
}

.iee-list-section .list-item:not(:first-child) {
  margin-left: 20px;
}

.add-btn-container {
  display: flex;
  align-items: center;
  padding: 20px 0;
  cursor: pointer;
}

.add-btn {
  width: 30px;
  height: 30px;
  border-radius: 50px;
  border: none;
  outline: none;
  background: rgb(50, 126, 184);
  text-align: center;
  color: #ffffff;
  font-size: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.add-btn-title {
  font-size: 18px;
  font-weight: bold;
  /*font-family: "Montserrat", sans-serif;*/
  color: #000000;
  margin-left: 15px;
}

.add-new-post {
  margin-top: 20px;
  padding: 30px 0;
  display: flex;
  flex-direction: column;
}

.new-post-header {
  height: 36px;
  padding: 2px 5px;
  font-size: 22px;
  font-weight: 700;
  font-family: "Roboto" !important;
  color: #367fb6;
  outline: none;
}

.new-post-content {
  margin-top: 10px;
  margin-left: 15px;
  display: flex;
  flex-direction: column;
}

.new-post-date {
  height: 25px;
  font-weight: bold;
  font-size: 16px;
  padding: 2px 5px;
  width: 105px;
  outline: none;
  border: none;
  border-bottom: 1px solid #979797;
  font-family: "Roboto" !important;
}

.new-post-date-container {
  margin-bottom: 8px;
}

.new-post-link-container,
.new-post-date-container {
  display: flex;
  position: relative;
  padding-left: 40px;
}

.new-post-link-helper,
.new-post-date-helper {
  position: absolute;
  left: 0;
  top: 5px;
  font-size: 15px;
  font-style: italic;
  font-weight: 500;
  /*font-family: "Open Sans", sans-serif;*/
}
.new-post-date-helper.description {
  top: 15px;
}

.new-post-link {
  height: 25px;
  font-style: italic;
  font-size: 16px;
  padding: 2px 5px;
  width: 210px;
  outline: none;
  border: none;
  border-bottom: 1px solid #979797;
  overflow: hidden;
  font-family: "Roboto" !important;
}

.add-new-post button {
  width: 148px;
  margin-top: 20px;
}

.country-list .removeIcon {
  position: absolute;
  top: -11px;
  right: -11px;
  border-radius: 100px;
  border: none;
  color: #000000;
  font-size: 12px;
  font-weight: bold;
  background: #f46e6e;
  cursor: pointer;
}

.country-list .removeIcon:hover {
  background: #ff0000 !important;
}

.list-content {
  display: flex;
}

.add-new-plan .add-btn-container {
  padding-top: 22px;
  padding-left: 10px;
}

.add-btn-container .add-btn {
  cursor: pointer;
}

.new-post-content .card-btn {
  width: 148px;
  margin-top: 10px;
}

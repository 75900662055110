button.p-paginator-page {
  margin-right: 15px !important;
  width: 3em !important;
}

body .p-paginator,
.p-paginator-icon,
body .p-paginator .p-paginator-prev,
body .p-paginator .p-paginator-first,
body .p-paginator .p-paginator-next,
body .p-paginator .p-paginator-last,
body .p-paginator .p-paginator-pages .p-paginator-page {
  color: black !important;
}

.p-datatable-header {
  border-top-right-radius: 25px !important;
  border-top-left-radius: 25px !important;
}

body .p-link {
  /*font: 16px "Montserrat" !important;*/
}

.p-paginator.p-component.p-paginator-bottom {
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  /*font: 16px "Montserrat" !important;*/
}

body .p-datatable .p-datatable-scrollable-header,
body .p-datatable .p-datatable-scrollable-footer {
  background-color: inherit !important;
}

colgroup.p-datatable-scrollable-colgroup > col {
  background-color: inherit !important;
}

body .p-datatable .p-datatable-header,
body .p-datatable .p-datatable-footer {
  /*font: 12px "Montserrat" !important;*/
  color: rgb(217, 217, 217) !important;
  background-color: rgb(96, 95, 95) !important;
}

.p-sortable-column-icon {
  color: rgb(217, 217, 217) !important;
}

.p-paginator-current {
  float: right;
  padding-left: 25px;
  padding-right: 25px;
}

div.p-paginator > div.p-dropdown > .p-dropdown-label {
  border: none !important;
  color: black !important;
  padding: 6px;
  font-size: 16px;
}

.rowItem {
  margin-bottom: 10px;
  margin-right: 30px;
  align-items: center;
}

.rowItemRightSide {
  margin-bottom: 10px;
  margin-right: 30px;
}

.filterBox {
  border-color: #979797;
  border-radius: 25px;
  min-height: 250;
  border-style: solid;
  border-width: 1px;
  padding-left: 35px;
  padding-right: 35px;
  padding-bottom: 10px;
  margin-right: 35px;
}

.group {
  margin-top: 25px;
  margin-left: 25px;
}

.icon-col {
  width: 45px;
}

button.search-button {
  background-color: #fcb016 !important;
  border-radius: 24px;
  border: 0;
  height: 48px;
  padding: 0 30px;
}

.search-button-label {
  font-size: 30px;
  font-weight: 700;
  color: white;
  /*font-family: "Montserrat";*/
}

/* .input-text-floating-label {
    display: block;

} */

body .p-panel .p-panel-content {
  border: none !important;
}

.p-panel-icons > .p-panel-header-icon > span.pi {
  font-size: 30px !important;
  color: rgb(50, 126, 184);
  margin-top: 15px;
}

body .p-link:focus {
  box-shadow: none !important;
}

.p-panel-header,
.p-panel-title {
  width: 100%;
}

.css-1wa3eu0-placeholder {
  color: black !important;
  /*font: 16px Montserrat !important;*/
}

::placeholder {
  color: black;
  opacity: 1;
}

tbody.p-datatable-tbody {
  font-size: 14px !important;
}

.buttonsHeader {
  padding-right: 1rem !important;
  justify-content: flex-end;
  display: flex;
}

.buttonsHeader .search-bar {
  text-align: right;
  margin-right: 0.5rem;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

.buttonsHeader .button-select {
  margin-right: 1rem;
  margin-left: 1rem;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

.buttonsHeader .button-export {
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

.buttonsHeader .button-export .p-button {
  border-radius: 10px !important;
  height: 30px !important;
  margin: 0 !important;
}

.buttonsHeader .button-select .p-button {
  height: 30px !important;
  margin: 0 !important;
}

.buttonsHeader .button-select .p-button-icon {
  margin-right: 10px;
}

.buttonsHeader .button-select .pi.p-c {
  margin-right: 10px;
  font-size: 14px !important;
}

.buttonsHeader .button-export .pi.p-c {
  font-size: 16px !important;
}

.p-dialog-mask.p-component-overlay.p-dialog-visible.p-dialog-center {
  z-index: 9900001 !important;
}

.usaid-details-container {
    /*font-family: 'Montserrat', sans-serif;*/
}

.usaid-details-container .usaid-details-header {
    position: relative;
    padding-top: 40px;
    background-color: #DBEBF5;
}

.usaid-details-container .usaid-details-header .usaid-details-header-back {
    min-height: 38px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
    padding-left: 32px;
    padding-right: 32px;
}

.usaid-details-container .usaid-details-header .usaid-details-header-back button {
    border: none;
    background: transparent;
    cursor: pointer;
    display: flex;
    align-items: center;
}


.usaid-details-container .usaid-details-header .usaid-details-header-back button:hover {
    background: unset;
    box-shadow: unset;
}

.usaid-details-container .usaid-details-header .usaid-details-header-back button svg {
    margin-right: 8px;
}

.usaid-details-container .usaid-details-header .usaid-details-header-back button span {
    /*font-family: 'Montserrat', sans-serif;*/
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: rgba(0, 0, 0, 0.76);}

.usaid-details-container .usaid-details-header .usaid-details-header-back .usaid-details-logo-container {
    position: absolute;
    right: 32px;
    top: 32px;
}
.usaid-details-container .usaid-details-header .usaid-details-header-back .usaid-details-logo {
    width: 175px;
    height: 30px;
}

.usaid-details-container .spacer {
    height: 1px;
    border: 1px solid rgba(54, 127, 182, 0.1);
    margin-left: 32px;
    margin-right: 32px;
}

.usaid-details-container .usaid-details-header .usaid-details-header-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 20px;
    padding-left: 32px;
    padding-right: 32px;
}

.usaid-details-container .usaid-details-header .usaid-details-header-info .usaid-details-label {
    /*font-family: 'Montserrat', sans-serif;*/
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: rgba(0, 0, 0, 0.7);
}

.usaid-details-container .usaid-details-header .usaid-details-header-info .usaid-details-name {
    /*font-family: 'Montserrat', sans-serif;*/
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
}

.usaid-details-container .usaid-details-header .usaid-details-header-info .usaid-details-name-container {
    display: flex;
    align-items: center;
    margin-top: 8px;
    margin-bottom: 8px;
}

.usaid-details-container .usaid-details-header .usaid-details-header-info .usaid-details-name-container .usaid-details-status {
    width: 71px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    background: #6DC14D;
    border-radius: 8px;
}

.usaid-details-container .usaid-details-header .usaid-details-header-info .usaid-details-name-container .usaid-details-status.closed {
    background: darkgray;
}

.usaid-details-container .usaid-details-header .usaid-details-header-info .usaid-details-name-container .usaid-details-status span {
    /*font-family: 'Montserrat', sans-serif;*/
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
}

.usaid-details-container .usaid-details-header .usaid-details-header-info .usaid-details-header-data-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.usaid-details-container .usaid-details-header .usaid-details-header-info .usaid-details-header-data-container .usaid-details-header-data-container-left-side span {
    padding-right: 24px;
}

.usaid-details-container .usaid-details-header .usaid-details-tab-selector-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 16px;
    margin-left: 32px;
}

.usaid-details-container .usaid-details-header .usaid-details-tab-selector-container .usaid-details-tab-selector {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 202px;
    height: 53px;
    border-radius: 16px 16px 0px 0px;
    border-width: 0;
    cursor: pointer;
    background: transparent;
}
.usaid-details-container .usaid-details-header .usaid-details-tab-selector-container .usaid-details-tab-selector:hover {
    background: unset;
    box-shadow: unset;
}

.usaid-details-container .usaid-details-header .usaid-details-tab-selector-container .usaid-details-tab-selector span {
    /*font-family: 'Montserrat', sans-serif;*/
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #197AB5;
}

.usaid-details-container .usaid-details-header .usaid-details-download-container {
    position: absolute;
    top: 115px;
    right: 32px;
}

.usaid-details-container .usaid-details-header .usaid-details-download-container button {
    display: flex;
    align-items: center;
    background: #367FB6;
    border-width: 0;
    border-radius: 27px;
    padding: 8px 22px;
    cursor: pointer;
}

.usaid-details-container .usaid-details-header .usaid-details-download-container button svg {
    margin-right: 5px;
}

.usaid-details-container .usaid-details-header .usaid-details-download-container button span {
    /*font-family: 'Montserrat',sans-serif;*/
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 10px;
    text-align: center;
    color: #FFFFFF;
}

.usaid-details-container .usaid-details-header .usaid-details-tab-selector-container .usaid-details-tab-selector.active {
    background-color: #FFFFFF;
}

.usaid-details-container .usaid-details-body {
    display: flex;
    flex-direction: row;
    margin-top: 32px;
    margin-left: 32px;
    margin-right: 32px;
}

.usaid-details-container .usaid-details-body .usaid-details-body-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 32px;
    border: 1px solid rgba(216, 216, 216, 0.6);
    border-radius: 16px;
    padding: 20px 24px;
}

.usaid-details-container .usaid-details-body .usaid-details-body-container.no-border {
    border: none;
    padding: 0;
}

.usaid-details-container .usaid-details-body .usaid-details-body-container .project-progress-status {
    position: absolute;
    right: 24px;
    top: 24px;
}

.usaid-details-container .usaid-details-body .usaid-details-body-container .project-progress-status span {
    /*font-family: 'Montserrat', sans-serif;*/
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #6DC14D;
}

.usaid-details-container .usaid-details-body .usaid-details-body-container .project-progress-status span.closed {
    color: darkgray;
}

.usaid-details-container .usaid-details-body .usaid-details-body-container .usaid-details-body-data {
    /*font-family: 'Montserrat', sans-serif;*/
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #605F5F;
    text-align: left;
}

.usaid-details-container .usaid-details-body .usaid-details-body-container .usaid-details-body-data-value {
    max-width: 350px;
    /*font-family: 'Montserrat', sans-serif;*/
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #605F5F;
    text-align: right;
}

.usaid-details-container .usaid-details-body .usaid-details-body-container .usaid-details-body-label-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 16px;
}

.usaid-details-container .usaid-details-body .usaid-details-body-container .usaid-details-body-label-container img {
    width: 18px;
    height: 18px;
    margin-right: 10px;
}

.usaid-details-container .usaid-details-body .usaid-details-body-container .usaid-details-body-label-container span {
    /*font-family: 'Montserrat', sans-serif;*/
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #000000;
}

.usaid-details-container .usaid-details-body .usaid-details-body-container .usaid-details-body-data-row {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 8px 0;
    border-bottom: 1px solid #D9D9D9;
}

.usaid-details-container .usaid-details-body .usaid-details-body-container .usaid-details-body-data-row.no-padding {
    padding-bottom: 0;
}

.usaid-details-container .usaid-details-body .usaid-details-body-container .usaid-details-body-data-row.no-border {
    padding-bottom: 0;
    border-bottom: none;
}

.usaid-details-container .usaid-details-body .usaid-details-body-left-side {
    width: 60%;
    padding-right: 16px;
}

.usaid-details-container .usaid-details-body .usaid-details-body-right-side {
    width: 40%;
    padding-left: 16px;
}

.usaid-details-container .usaid-details-body .usaid-details-body-right-side .spacer {
    width: 100%;
    height: 1px;
    border: none;
    border-bottom: 1px solid #D9D9D9;
    margin: 10px 0;
}

.usaid-details-container .usaid-details-body .usaid-details-body-right-side .usaid-details-body-data-row {
    border: none;
}

.usaid-details-container .usaid-details-disclaimer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 32px;
}

.usaid-details-container .usaid-details-disclaimer span {
    /*font-family: 'Montserrat', sans-serif;*/
    font-style: italic;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.76);
    text-align: left;
}

.usaid-details-container .usaid-details-disclaimer span:first-child {
    margin-bottom: 3px;
}

@media (max-width: 1400px) {

    .usaid-details-container .usaid-details-header .usaid-details-header-info .usaid-details-header-data-container .usaid-details-header-data-container-left-side {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex-wrap: wrap;
    }

    .usaid-details-container .usaid-details-header .usaid-details-header-info .usaid-details-header-data-container .usaid-details-header-data-container-left-side span {
        margin-bottom: 5px;
    }
}
